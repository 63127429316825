import React from 'react'

export default function Footer() {
    return (
        <div id='footer'>
            <footer class="site-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <h2 className='h6 text-light'>About</h2>
                            <p class="text-justify">
                            The passionate Metatech team makes stunning websites and apps. Our talented developers build dynamic websites and user-friendly apps for your business.Our SEO services improve your website's visibility and rankings in addition to development. Digital marketing boosts business growth by increasing brand awareness, organic traffic, and meaningful leads. Metatech specializes in web, app, design, SEO, and digital marketing. Consult for free!

                            </p>
                        </div>

                       
                    </div>
                    <hr/>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 col-sm-6 col-xs-12">
                            <p class="copyright-text">Copyright &copy; 2024 All Rights Reserved by 
                                <span href="#" className='mx-1'>Metatech</span>
                            </p>
                            <p class="copyright-text">Site developed by: <a href='/Metatech.pdf' target="_blank" download> Metatech <span className='d-none'>Metatech-PDF</span></a>
                                
                            </p>
                        </div>

                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <ul class="social-icons">
                                <li><a class="facebook" target='_blank' href="https://www.facebook.com/profile.php?id=100094100574663"><i class="fa fa-facebook"></i></a></li>
                                <li><a class="twitter" target='_blank' href="https://twitter.com/metatech_offic"><i class="fa fa-twitter"></i></a></li>
                                <li><a class="linkedin" target='_blank' href="https://www.linkedin.com/company/metatech-official/"><i class="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}